@use '@angular/material' as mat;
@use "sass:color";
@import "theme";

// --------------------------------------------------
// SPACING (fluid scale calculator: https://www.fluid-type-scale.com)
// --------------------------------------------------

$spacing-0: 0;
$spacing-1: clamp(5px, 0.38vw + 2.73px, 10px); // 5px to 10px
$spacing-2: clamp(10px, 0.76vw + 5.45px, 20px); // 10px to 20px
$spacing-3: clamp(20px, 1.52vw + 10.91px, 40px); // 20px to 40px
$spacing-4: clamp(40px, 3.03vw + 21.82px, 80px); // 40px to 80px
$spacing-5: clamp(80px, 6.06vw + 43.64px, 160px); // 80px to 160px
$spacing-auto: auto;
$spacing-2neg: clamp(-10px, 0.76vw + 5.45px, -20px); // -10px to -20px

// --------------------------------------------------
// RADIUS
// --------------------------------------------------

$radius-0: 0;
$radius-1: clamp(8px, 0.38vw + 2.73px, 12px); // 8px to 12px
$radius-2: clamp(12px, 0.76vw + 5.45px, 26px); // 12px to 26px
$radius-3: clamp(16px, 1.52vw + 10.91px, 24px); // 16px to 24px
$radius-4: clamp(24px, 3.03vw + 21.82px, 32px); // 24px to 32px
$radius-5: clamp(32px, 6.06vw + 43.64px, 64px); // 32px to 64px
$radius-rounded: 50%;

// Figma semantic matching
:root {
    --radius-s: #{$radius-1};
    --radius-m: #{$radius-2};
    --radius-l: #{$radius-3};
    --radius-xl: #{$radius-4};
    --radius-xxl: #{$radius-5};
}

// --------------------------------------------------
// COLORS
// --------------------------------------------------

// Figma colors
:root {
    --body--background-color: #E8EDF6;

    --milan-vert: #37C6C3;
    --milan-vert-fonce: #146A68;

    --bj-vert-ok: #89C52B; // fond boutons ok
    --bj-vert-ok-shadow: #659815; // bordures boutons ok
    --bje-vert: #5DD3D1;

    --bje-rouge: #FF0000;

    --Gris_bouton: #F4F4F4;
    --bj-gris-clair: #EBEBEB;

    --bje-jaune: #FFED00;
    --bje-jaune-shadow: #E4C002;

    --faux-blanc: #F9FBFF;
    --gris-de-fond: #E8EDF6;
    --gris-de-contour: #CBD1DB;

    --noir: #000000;
    --noir_5: rgba(0, 0, 0, 0.05);
    --noir_10: rgba(0, 0, 0, 0.1);
    --blanc: #FFFFFF;

    --orange: #FF790D; // complémentaire partagé avec le vert (kuler)
    --vert: #009944; // barre de progression maquette
    --vert2: #016B3E; //

    --accent: #{map_get($md-accent, 500)};
    --accent-border: var(--bje-jaune-shadow); // darken( map_get($md-accent, 500), 30%);
    --accent-color: $default-font-color; // darken( map_get($md-accent, 500), 30%);
}

// Main color aliases
$primary-c: map_get($mat-fusedark, 500);
$secondary-c: var(--milan-vert);
$quaternary-c: map_get($md-blue, 500);
$accent-c: map_get($md-accent, 500);
$black-c: black;
$white-c: white;
$off-white: var(--faux_blanc);
$dark_grey: map_get($mat-fusedark, 500);
$background-grey: var(--gris-de-fond);

// --------------------------------------------------
// TYPOGRAPHY
// --------------------------------------------------

$typography-font-name: 'lato';
$typography-font-name2: 'lato';
$font-family: '#{$typography-font-name}, "Helvetica Neue", Arial, sans-serif';
$font-family2: '#{$typography-font-name2}, "Helvetica Neue", Arial, sans-serif';
$root-em: 10px; // Root element font-size
$default-font-size: 14px;
$default-font-weight: 400;
$default-line-height: 1.4;
$default-font-color: $black-c;
$default-font-contrast-color: $white-c;

:root { // TODO: remove css custom properties and use scss variables
    --typo1: #{$typography-font-name};
    --typo2: #{$typography-font-name2};
}

// --------------------------------------------------
// COMPONENTS
// --------------------------------------------------

// ========== LINKS ==========

$link--color: $default-font-color;
$link--text-decoration: underline;

// ========== SPLASH SCREEN ==========

$splash-screen__spinner--border-color: $secondary-c;

// ========== LOGO ==========

$logo-icon__brand-background--fill: $primary-c;
$logo-icon--square__brand-background--fill: map_get($mat-white, 500);
$logo-icon--square__brand-text-ia--color: $primary-c;

// ========== BASIC PAGE ==========

$basic-page--color: $default-font-color;
$basic-page__icon--background-color: var(--milan-vert);
$basic-page__li--background-color: $accent-c;

// ========== LEGAL NOTICE ==========

$legal-notice--background-color: $background-grey;
$legal-notice--color: null;
$legal-notice__link--color: $default-font-color;
$legal-notice__link--text-decoration: underline;
$legal-notice__panel-content--background-color: $background-grey;
$legal-notice__panel-title--color: $default-font-color;
$legal-notice__panel--expanded--box-shadow: 0 0.4rem 0.4rem 0 rgba(0, 0, 0, 0.03),
                                            0 5rem 3rem 0 $primary-c;

// ========== LESSON PAGE ==========

$lesson-page__lesson-section--border: 1px solid var(--gris-de-contour);
$lesson-page__generic-element--background-color: var(--bj-gris-clair);
$lesson-page__file-card--color: $default-font-color;

// ========== CARDS ==========

$card--border-radius: null;

$card__header--background-color: white;
$card__header--color: $secondary-c;
$card__header--box-shadow: 0px 6px 15px -7px rgba(0, 0, 0, 0.14);
$card__header--breadcrumb--background-color: $secondary-c;
$card__header--breadcrumb--color: white;
$card__header--gamecode-card--background-color: $secondary-c;
$card__header--gamecode-card--color: white;

$card-content--padding: '15px 18px 0';
$card-content--background: var(--faux-blanc);
$card-content--font-size: $default-font-size;
$card-content--font-weight: $default-font-weight;
$card-content--line-height: $default-line-height;
$card-content__field-content--bubble-format: true; // concern some fields like educationnalLevel to present in bubble format
$card-content-short-description--padding: '0 0 10px 0';

$card-action--margin: '0 !important'; // '-63px 0 0 226px !important';
$card-action--padding: '8px 16px !important'; // '8px 16px !important';
$card-action__mat-icon--fill-color: black;
$card-action__mat-menu-panel__mat-icon--color: black;
$card__actions--border: 1px solid rgba(203, 209, 219, 1);

$fake-card--background-color: $accent-c;
$fake-card--color: $default-font-color;

// ========== MENU PANEL ==========

$menu-panel--font: 700 #{$default-font-size}/#{$default-line-height} #{var(--typo1)}, #{var(--typo2)}, sans-serif;

$menu-panel__button--background-color: transparent;
$menu-panel__button--color: $default-font-color;

// ========== TOP MAIN TOOLBAR ==========

$main-toolbar--background-color: var(--faux_blanc);
$main-toolbar--box-shadow: null;
$main-toolbar__user-button--color: $default-font-color;

// ========== BANNER INFO ==========

$banner-info--background-color: var(--faux-blanc);
$banner-info--color: $default-font-color;
$banner-info--border-radius: 5px;
$banner-info__mat-toolbar--line-height: $default-line-height;
$banner-info__mat-toolbar__paragraph--font-weight: $default-font-weight;

// ========== DIALOG MODALES ==========

$dialog__link--color: $default-font-color;
$dialog__link--text-decoration: underline;

$dialog__submit-button--disabled--color: $default-font-color;

$dialog__title--background-color: $secondary-c;
$dialog__title--color: $default-font-color;

// ========== TABS ==========

$tabs__underline--border-top-color: var(--bje-rouge);

// ========== SCROLLBAR ==========

$perfectscrollbar-hover-background: mat.get-color-from-palette($accent);
$perfectscrollbar-hover-width: 11px;

// ========== CHIPS ==========

$standard-chip--background-color: var(--milan-vert);
$standard-chip--color: $default-font-contrast-color;

// ========== BUTTON ==========

$button--accent-color: $default-font-color;
$button--primary-color: $default-font-color;

$button-inverted--color: var(--noir);
$button-inverted--background-color: var(--blanc);

$button--accent--border: '1px solid #{var(--accent-border)}';
$button--accent--box-shadow: '0px 4px 10px rgba(0, 0, 0, 0.25)';
$button--accent--border-radius: '4px';
$button--accent--font-weight: '700';
$button--accent--size: $default-font-size;

$button--actif--color: $default-font-contrast-color;
$button--actif--background-color: $secondary-c;

$mat-button-base-extend: null;
$mat-raised-button-extend: '%button--accent';

$button-mat-fab-dimension: 65px;
$button-mat-fab-stroke-color: map_get($accent, 500);

$button__mat-menu-trigger--extend: '%button--only-icon'; // null ou Nom Placholder
$button__mat-menu-trigger--color: $default-font-color; // var(--bje-rouge); // white

// ========== FILTERS ==========

$filters--background-color: white;
$filters--border: 1px solid var(--gris-de-contour);
$filters--border-radius: 5px;
$filters--elevation: true;

$filters__button--disabled--color: $default-font-color;
$filters__button-collapse--border-color: $default-font-color;
$filters__button-collapse--mat-icon--color: $default-font-color;
$filters__button-collapse--mat-icon--border-color: transparent;

// ========== FORM FIELDS ==========

$form-field-input-color: $default-font-color;
$form-field-input-label-color: $default-font-color;
$form-field-input__label-float--color: $default-font-color;
$form-field-input-label-color-focused: $default-font-contrast-color;
$form-field-input-font: 500 #{$default-font-size}/36px "#{var(--typo1)}", sans-serif;
$form-field-label-font: 500 14px/16px "#{var(--typo1)}", sans-serif;
$form-field-label-text-transform: "uppercase";
$form-field-label-top: 0;
$form-field-underline-background-color: rgba(0, 0, 0, 0.4);
$form-field-underline-bottom: 10px;

$form-field-flex-border: 1px solid $default-font-color;
$form-field-flex-border-top: none;
$form-field-flex-border-bottom: none;
$form-field-flex-border-left: none;
$form-field-flex-border-right: none;
$form-field-ripple-background: $default-font-color;
$form-field-infix-padding: '0 5px 5px 5px';
$form-field-infix-line-height: 2em;
$form-field-margin-bottom: 0.5em;
$form-field-select-panel-background-color: map_get($mat-white, 500);

$mat-form-field-checkbox-color: rgba(0, 0, 0, 0.5);
$mat-form-field-checkbox-background-color: transparent;
$mat-form-field-checkbox-checkmark-stroke-color: var(--bje-rouge);
$mat-form-field-checkbox-border: '2px solid #{var(--bje-rouge)}';
$mat-form-field-checkbox-border-radius: 0;
$mat-form-field-checkbox-color-checked: mat.get-color-from-palette($accent);
$mat-form-field-checkbox-background-color-checked: mat.get-color-from-palette($accent);

$mat-form-field-option-color: rgba(0, 0, 0, 0.5);

$mat-form-field__select-arrow--color: var(--bje-rouge);

// ========== GROUPS MANAGEMENT ==========

$groups-inline__button-add__disabled--color: rgba(0, 0, 0, 0.26);
$groups-inline__button-add__disabled--background-color: rgba(0, 0, 0, 0.12);

// ========== NAVBAR SIDEBAR ==========

/* *** navbar-header *** */
// Couleur de fond
$navbar-header-background-color: 'transparent';
$navbar-header-box-shadow: 'none';
$navbar-header-icon-dimensions: 32px;
$navbar-header-icon-svg-color: map_get($mat-white, 500);
$navbar-header--gap: unset;
$navbar-header--height: 70px;
$navbar-header--padding: 0 16px 0 24px;

$navbar-header__menu-button--width: 40px;
$navbar-header__menu-button--height: 40px;
$navbar-header__menu-button--transform: unset;
$navbar-header__menu-icon--width: 24px;
$navbar-header__menu-icon--height: 24px;
$navbar-header__menu-icon--aspect-ratio: unset;

// Couleur de fond du sous menu ouvert
$nav-collapsable--background: #{var(--milan-vert)};
$nav-collapsable--border-radius: unset;

// Dimension du logo
$logo-icon-svg-color: mat.get-color-from-palette($mat-white, 500);
$logo-icon-svg-width: 158px;
$logo-icon-svg-height: 38px;

// Content
$navbar-content-background-color: $primary-c;
$navbar-content--padding-bottom: 50px;

// Items
$navbar-vertical__item--padding-right: unset;
$navbar-vertical__item--margin-top: unset;

// Couleurs des élements textuels et icones
$nav-link-title-font-size: 1.6rem;
$nav-link-title-color: $default-font-color;
$nav-link-title-color-active: $default-font-color;
$nav-icon-color: $default-font-color;

$nav-link-icon-dimension: 26px;
$nav-link-icon-font-size: $nav-link-icon-dimension;
$nav-link-icon-width: $nav-link-icon-dimension;
$nav-link-icon-height: $nav-link-icon-dimension;
$nav-link-icon-line-height: $nav-link-icon-dimension;
$nav-link-icon-color-active: $default-font-color;
$nav-link-icon-color-hover: $nav-link-icon-color-active;
$nav-link__icon--margin-right: 16px;

$nav-link__collapsable-arrow--transform: rotate(90deg);

$nav-link--border-radius: unset;
$nav-link--line-height: 1.4;
$nav-link--padding: 0 16px 0 24px;
$nav-link--hover--background-color: $secondary-c;
$nav-link--active--background-color: $nav-link--hover--background-color;
$nav-link__collapse-open--active--background-color: $nav-link--hover--background-color;
$nav-link__collapse-open__children__before--active--background-color: #{$white-c};
$nav-link__collapse-open__children__before--hover--background-color: $nav-link__collapse-open__children__before--active--background-color;
$use_button_app_with_img: false;

// ========== TABLE ==========

$table--background-color: null;

$table__thead--background-color: map_get($primary, 500);
$table__thead--font-color: $default-font-color;
$table__thead--checkbox-color: $default-font-contrast-color;
$table__thead--border: 1px solid rgba(map_get($primary, 500), 0.5);
$table__thead--border-radius: 5px;
$table__thead--font-size: $default-font-size;

$table__tbody-row--background-color: null;
$table__tbody-row--hover--background-color: $secondary-c;
$table__tbody-row--border-radius: 10px;
$table__tbody-row--box-shadow: 0 1px 10px rgba(0, 175, 236, 0.4);

$table__mat-cell--color: $default-font-color;
$table__mat-cell--SelectedAndHover--color: $default-font-contrast-color;

$table-icon-stroke-color: map_get($accent, 500);
$table-icon-stroke-color-hover: map_get($accent, 300);
$table-icon-stroke-inactive-color: #C4C4C4;

$table__button__icon--color: var(--bje-rouge);

// ========== PAGINATOR ==========

$paginator__button--color: $secondary-c;

// ========== SUMMARY ==========

$summary--background-image: unset;
$summary--background-size: unset;
$summary--background-position: unset;
$summary--background-repeat: unset;
$summary__footer--background-color: lightgray;

// --------------------------------------------------
// MODULES
// --------------------------------------------------

// ========== ACCOUNT MANAGEMENT ==========

$profile-box--border-radius: null;
$profile-box--box-shadow: null;
$profile-box--color: null;

$profile-box__actions--background-color: null;

$profile-box__header--background-color: null;
$profile-box__header--color: null;
$profile-box__header--font-size: null;
$profile-box__header--font-weight: null;

// ========== ACTIVITIES ==========

$activities__button-selected--background-color: var(--milan-vert);

/* ** Generic stepper ** */
$stepper__button--color: $default-font-color;
$stepper__button--background-color: var(--blanc);
$stepper__button--box-shadow: '0px 4px 4px rgba(0, 0, 0, 0.25)';

$stepper__button-hover--color: $default-font-color;
$stepper__button-hover--background-color: var(--milan-vert);
$stepper__button-hover--box-shadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)';

$stepper__button-active--color: var(--milan-vert); // var(--bje-jaune);
$stepper__button-active--box-shadow: '0px 4px 4px rgba(0, 0, 0, 0.25)';

$stepper__button-current--color: $default-font-color;
$stepper__button-current--background-color: var(--milan-vert); // var(--bje-jaune);
$stepper__button-current--box-shadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)';

$stepper__mat-select-panel--background: 'white';
$stepper__mat-select-panel--border: 'thin solid e9e9e9;';
$stepper__mat-select-panel--color: #000000;
$stepper__mat-select-panel__span--color: black;
$stepper__mat-select--border: 'none';
$stepper__mat-select_span--color: black;
$stepper__mat-select-panel__generic_stepper_mat-select-panel--background-color: var(--milan-vert);
$stepper__mat-select-panel__generic_stepper_mat-select-panel__hover--background-color: '#bcf1f0';
$stepper__mat-select-panel--box-shadow: 'none';

$stepper__button--padding: 8px 10px;
$stepper__button-disabled--color: black;
$stepper__button-disabled--background-color: var(--bje-jaune);

// ========== AUTHENTICATION ==========

//fuse-login and register
$fuse-authentication-color: $default-font-color;
$fuse-authentication-field-input-font: 500 14px/32px "#{var(--typo1)}", sans-serif;
$form-field-label-height: 1em;
$fuse-authentication-border-radius: 20px;
$fuse-authentication-field-infix-padding: '0 15px 0 15px';
$fuse-authentication-background: $secondary-c;
$fuse-authentication--box-shadow: null;

$fuse-authentication__field-flex--border: none;
$fuse-authentication__field-flex--border-bottom: null;
$fuse-authentication__field-flex--border-radius: 5px;

$fuse-authentication__label--color: $default-font-color;
$fuse-authentication__label--float--color: $default-font-color;

$fuse-authentication__link--color: $default-font-color;
$fuse-authentication__link--text-decoration: underline;

$fuse-authentication-title-font: 700 24px/28px "#{var(--typo1)}", sans-serif;
$fuse-authentication-title-letter-spacing: "0.25px";

$main-toolbar__button__btn__goToLoginPage--color: $default-font-color;

// login-form
$fuse-authentication-login-width: 483px;
$fuse-authentication-logo-width: 274px;
$fuse-authentication-logo-margin: '5px auto 0 auto';
$fuse-authentication-title-margin: '10px 0 10px 0';

// breadcrumb
$breadcrumb__ul__li--color: var(--milan-vert);

// ========== CORPUS ==========

$corpus__details-sidebar--background-color: $secondary-c;
$corpus__details-sidebar--color: $default-font-color;

$corpus__helper--empty--background-color: $accent-c;
$corpus__helper--empty--color: $default-font-color;

$corpus__table__row--selected--background-color: $secondary-c;
$corpus__table__row--selected-extension-line--background-color: $secondary-c;
$corpus__table__row--hover--color: $default-font-color;
$corpus__table__row--mat-icon--color: var(--bje-rouge);

// ========== ASSIGNATION ==========

$assignment-by-steps__step--background-color: $primary-c;

$followed-filter__toolbar--background-color: $primary-c;

$followed-list__assignment-button--background-color--hover: $primary-c;
$followed-list__assignment-header--visually-hidden: false;
$followed-list__assignment-icon--color: $default-font-color;
$followed-list__concept-chip--background-color: $black-c;
$followed-list__content--color: $default-font-color;
$followed-list__group-length--background-color: $primary-c;
$followed-list__action-button--size: m;
$followed-list__action-button--type: tertiary;
$followed-list__filter-button--type: tertiary;
$followed-list__paginator--background-color: $primary-c;
$followed-list__table--background-color: $primary-c;

$followed-tabs--background-color: $white-c;
$followed-tabs__underline--border-top-color: $secondary-c;
$followed-tabs__nav-button--color: $default-font-color;
$followed-tabs__nav-button--font-family: inherit;

// ========== GRAPH MATHIA ==========

$graph-mathia__progress-graph--background-color: $accent-c;
$graph__mat-card-header--color: black;

// ========== PROGRESS DIALOG ==========

$progression__dialog-background-color: var(--gris_de_contour, #636A76);

// ========== HOME PAGE ==========

$app-home-page__banner--height: 160px;
$app-home-page__banner--position: static;
$app-home-page__card-header--color: $default-font-color;
$app-home-page__header--background-color: unset;
$app-home-page__header--background-image: none;
$app-home-page__header--border-radius: 7px;
$app-home-page__header--padding: 15px 15px 0 15px;
$app-home-page__header--position: static;

// ========== BODY ==========

$body--background-color: #FFFFFF;

// ========== COLLECTION ==========

$collection__link--color: $secondary-c;
$collection__link--font-weight: 700;

// ========== BD TOOL ==========


$bd-tool__pagination-button--background-color: $accent-c;
$bd-tool__pagination-button--color: white;
$bd-tool__pagination-link--background-color: white;
$bd-tool__pagination-link--color: $accent-c;
$bd-tool__pagination-link--active--background-color: #000;
$bd-tool__pagination-link--active--color: white;